* {
    box-sizing: border-box;
}

@import url('https://fonts.googleapis.com/css?family=Montserrat:500');

body {
    font-family: 'Montserrat', sans-serif;
    margin: 0;
    font-size: 14pt;
    font-weight: 900;
}

a {
    position: relative;
    user-select: none;
}

.nav {
    color: black;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 6rem;
    padding-top: 5px;
    padding-left: 1rem;
    padding-right: 3rem;
}

.nav ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    gap: 1.25rem;
    color: black;
}

.nav a {
    color: inherit;
    text-decoration: none;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0.1rem;
    min-width: 20px;
    text-align: center;
}

.nav li {
    position: relative;
    display: block;
    transition: color 0.1s, background-color 0.1s;
}

.nav li:hover {
    color: #009a44;
}

.nav li:focus,
.nav li:active {
    color: #009a44;
}

.nav li::before {
    content: '';
    display: block;
    position: absolute;
    top: 100%;
    height: 3px;
    width: 100%;
    background-color: #009a44;
    transform-origin: center top;
    transform: scale(0, 1);
    transition: color 0.1s, transform;
}

.nav li:active::before {
    background-color: #6b0fcc;
}

.nav li:hover::before,
.nav li:focus::before {
    transform-origin: center top;
    transform: scale(1, 1);
}

.landing--social {
    font-size: 35px;
}

.menu-toggle {
    display: none;

}

.navbar.desktop-only {
    display: none;
}

/* Media Query for smaller screens */
@media (max-width: 768px) {
    .nav {
        flex-direction: column;
        align-items: flex-start;
        margin-top: 30px;
    }

    .nav ul {
        flex-direction: column;
        align-items: center;
        margin-top: 1rem;
    }

    .nav li {
        display: none;
        margin-bottom: 0.5rem;
        text-align: center;
    }


    .nav.menu-open li {
        display: block;
    }

    .menu-toggle {
        display: block;
        background: none;
        border: none;
        cursor: pointer;
        position: relative;
        z-index: 1;
        margin-left: 1rem;
        margin-bottom: -80px;
        /* Adjust the margin as desired */
        margin-top: 0.5rem;
    }

    .menu-icon {
        display: block;
        width: 25px;
        height: 3px;
        background-color: black;
        transition: transform 0.1s;
        position: relative;
    }

    .menu-icon::before,
    .menu-icon::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 3px;
        background-color: black;
        transition: transform 0.1s;
    }

    .menu-icon::before {
        top: -8px;
    }

    .menu-icon::after {
        top: 8px;
    }

    /* Add styles for close icon */
    .menu-icon.menu-open .menu-icon::before {
        transform: rotate(45deg) translate(0, 2px);
    }

    .menu-icon.menu-open .menu-icon::after {
        transform: rotate(-45deg) translate(0, -2px);
    }

    .left-nav {
        display: none;
    }
}