* {
    margin: 0;
    padding: 0;
}

body {
    margin: 0;
    padding: 0;
    background-color: #FFFFE8;
}


.main-container {
    background-color: #FFFFE8;
    width: 100%;
    height: auto;
    overflow-x: hidden;
}

.background-container {
    height: 30vh;
    width: 100%;
    background-image: url('DelMarBackground.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.landing-container {
    position: relative;
    z-index: 1;
}

/*Navbar*/
.navbar-separator {
    margin-top: 15px;
    margin-left: 5%;
    margin-right: 10%;
    border: none;
    border-top: 1px solid #000;
}

/*Author Section*/
.author-description {
    padding-top: 5vh;
    padding-bottom: 5vh;
    margin-left: 5%;
    margin-right: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.author-profilePicture img {
    margin-top: -1.5rem;
    width: 40vh;
    max-width: 400px;
    border-radius: 50%;
    margin-right: 5vh;
}

.author-bio {
    width: 100vh;
    max-width: 1000px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.author-content h6 {
    font-weight: 500;
    font-size: 1.25rem;
    margin-bottom: -0.85rem;
    opacity: 100%;
    font-weight: 600;
    color: #787474;
}

.author-content h1 {
    font-weight: 600;
    font-size: 2.75rem;
    line-height: 110%;
    margin: 1rem 0;
    /* margin-bottom: -0.85rem; */
    opacity: 100%;
}

.author-content p {
    margin-top: .5rem;
    font-weight: 500;
    font-size: 17pt;
    opacity: 100%;
    line-height: 1.35;
}

.buttons {
    margin-top: 15px;
    padding-bottom: 5%;
    align-self: flex-start;
}

.buttons .btn1 {
    background-color: #2586a4;
    color: white;
    text-decoration: none;
    border-radius: 30px;
    padding: 10px;

}

.buttons .btn1:hover {
    background-color: #009a44;
}

.buttons .btn2 {
    background-color: #FFD4D4;
    color: black;
    text-decoration: none;
    border-radius: 30px;
    margin: 10px;
    padding: 10px;
}

.buttons .btn2:hover {
    background-color: #009a44;
}

.buttons .amazon-btn {
    background-color: #2586a4;
    color: white;
    text-decoration: none;
    border-radius: 30px;
    padding: 15px;
}

.buttons .amazon-btn:hover {
    background-color: #FF9900;
    color: black;
}


.buttons {
    display: flex;
    justify-content: center;
    align-items: center;
}

.buttons .btn3 {
    background-color: #FFD4D4;
    color: black;
    text-decoration: none;
    text-align: center;
    border-radius: 30px;
    margin: 10px;
    padding: 12px;
    width: 325px;
    /* Adjust the width value as per your preference */
}

.buttons .btn3:hover {
    background-color: #009a44;
}


.separator {
    /* margin-top: 15px; */
    margin-left: 5%;
    margin-right: 10%;
    border: none;
    border-top: 1px solid #787474;
}

/*Center content*/
.container-center {
    padding-top: 5vh;
    padding-bottom: 5vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.body-center h1 {
    text-align: center;
    font-weight: 600;
    font-size: 2.25rem;
    line-height: 110%;
    margin: 1rem 0;
    margin-bottom: 2rem;
    opacity: 100%;
}

.body-center p {
    text-align: center;
    margin-top: .75rem;
    font-weight: 500;
    font-size: 16pt;
    opacity: 100%;
    line-height: 1.45;
    /* width: 100vh; */
    max-width: 1100px;
}

.contact-form {
    margin: 0 auto;
    width: 100%;
    /* max-width: 1000px; */
    height: 900px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.contact-form iframe {
    width: 100%;
    height: 100%;
    border: none;
}


/* Responsive Styles */

/* For smaller screens, adjust the layout */
@media (max-width: 768px) {
    body {
        text-align: center;
    }

    .main-container {
        overflow-x: initial;
    }

    .author-profilePicture img {
        max-width: 80%;
        border-radius: 50%;
        margin: 0 auto;
        margin-bottom: 30px;
        display: block;
    }

    .background-container {
        height: auto;
        background-position: top;
    }

    .author-description {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .buttons {
        margin: 0 auto;
        margin-top: 25px;
        text-align: center;
    }

    .author-bio {
        width: 100%;
    }

    .container-center {
        padding-top: 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .body-center {
        margin-left: 5%;
        margin-right: 5%;
        width: 100%;
    }

    .body-center h1,
    .author-content h1 {
        font-size: 1.75rem;
    }

    .author-content p,
    .body-center p {
        font-size: 12pt;
        margin-left: 5%;
        margin-right: 5%;
    }

    .contact-form {
        width: auto;
        height: 1100px;
        /* Adjust the margin-bottom value as needed */
        /* Adjust the height as needed for smaller screens */
        flex-direction: column;
    }

    .contact-form iframe {
        width: 100%;
        height: 100%;
        border: none;
    }
}

/* For even smaller screens */
@media (max-width: 576px) {

    .author-content h1,
    .body-center h1 {
        font-size: 1.75rem;
    }

    .author-content p,
    .body-center p {
        font-size: 12pt;
    }

    .contact-form {
        width: auto;
        height: 1100px;
        /* margin-bottom: 10%; */
        /* Adjust the height as needed for smaller screens */
        flex-direction: column;
    }
}