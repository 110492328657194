* {
    margin: 0;
    padding: 0;
}

body {
    margin: 0;
    padding: 0;
    background-color: #FFFFE8;
}


.about-main-container {
    background-color: #FFFFE8;
    width: 100%;
    height: auto;
    overflow-x: hidden;
}

.about-background-container {
    height: 35vh;
    width: 100%;
    background-image: url('DelMarView.jpg');
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: 50% 34%;
}

.about-landing-container {
    position: relative;
    z-index: 1;
}

.author-book-profilePicture img {
    margin-top: -1.5rem;
    width: 45vh;
    height: 60vh;
    max-height: 475px;
    max-width: 400px;
    border-radius: 5%;
    margin-right: 10vh;
}

.testimonial-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
}

.testimonial-btn {
    background-color: transparent;
    border: none;
    font-size: 24px;
    cursor: pointer;
}

.testimonial-content {
    text-align: center;
    padding: 0 20px;
    white-space: pre-line;
    animation: testimonialAnimation 15s infinite;
}

.testimonial-text {
    font-size: 18px;
    margin-bottom: 10px;
}

.testimonial-author {
    font-style: italic;
}

@keyframes testimonialAnimation {
    0% {
        opacity: 0;
        transform: translateX(-50%);
    }

    20% {
        opacity: 1;
        transform: translateX(0%);
    }

    80% {
        opacity: 1;
        transform: translateX(0%);
    }

    100% {
        opacity: 0;
        transform: translateX(50%);
    }
}

.testimonial-container:hover .testimonial-content {
    animation-play-state: paused;
}

/* Responsive Styles */

/* For smaller screens, adjust the layout */
@media (max-width: 768px) {
    body {
        text-align: center;
    }

    .main-container {
        overflow-x: initial;
    }

    .author-profilePicture img {
        max-width: 80%;
        border-radius: 50%;
        margin: 0 auto;
        margin-bottom: 30px;
        display: block;
    }

    .author-book-profilePicture img {
        max-width: 100%;
        border-radius: 50%;
        margin: 0 auto;
        margin-bottom: 30px;
        display: block;
    }

    .about-background-container {
        height: auto;
        background-position: top;
    }

    .author-description {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .buttons {
        margin: 0 auto;
        margin-top: 25px;
        text-align: center;
    }

    .author-bio {
        width: 100%;
    }

    .container-center {
        padding-top: 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .body-center {
        margin-left: 5%;
        margin-right: 5%;
        width: 100%;
    }

    .body-center h1,
    .author-content h1 {
        font-size: 1.75rem;
    }

    .author-content p,
    .body-center p {
        font-size: 12pt;
        margin-left: 5%;
        margin-right: 5%;
    }
}

/* For even smaller screens */
@media (max-width: 576px) {

    .author-content h1,
    .body-center h1 {
        font-size: 1.75rem;
    }

    .author-content p,
    .body-center p {
        font-size: 12pt;
    }
}